<script>
import { animate, spring } from 'motion'

export default {
  name: 'Notification',

  data() {
    return {
      hide: true,
      timer: null,
      timerAnimation: null,
      notification: {
        timeout: 150000,
        type: 'error',
        title: 'No rooms found with the selected options.',
        body: 'We apologize that we don\'t have any rooms available for your searched dates. Ensure you have selected the minimum nights required in the date range selected.',
      },
    }
  },

  computed: {
    type() {
      return this.notification.type || 'info'
    },

    containerClasses() {
      if (this.type === 'warning')
        return 'bg-yellow-100 text-yellow-700'
      else if (this.type === 'success')
        return 'bg-green-100 text-green-700'
      else if (this.type === 'error')
        return 'bg-red-100 text-red-700 '

      return ['bg-navy-800', 'text-white']
    },

    progressBarClasses() {
      if (this.type === 'warning')
        return 'bg-yellow-500'
      else if (this.type === 'success')
        return 'bg-green-500'
      else if (this.type === 'error')
        return 'bg-red-500'

      return ['bg-gray-200']
    },

    timerLength() {
      return 15000
    },
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search)
    const errorMessage = urlParams.get('ERROR_MESSAGE')
    console.log('Error message', errorMessage)

    if (errorMessage) {
      if (errorMessage === 'NO_ACTIVITIES_FOUND') {
        this.notification.title = 'There is a small issue with our booking system.'
        this.notification.body = 'Due to server maintenance, activities are currently not bookable online. To make a reservation, please call 800 Atlantis (800-285-2684). We apologize for any inconvenience.'
      }

      this.hide = false
      this.initTimers()
      this.animateIn()
    }
  },

  methods: {
    initTimers() {
      if (this.notification.timeout !== false) {
        this.startTimerAnimation()
        this.startTimer(this.timerLength)
      }
    },

    startTimer(length) {
      this.timerEnd = new Date().getTime() + length
      this.timer = setTimeout(() => {
        this.closeNotification()
      }, length)
    },

    startTimerAnimation() {
      this.timerAnimation = animate(
        this.$refs.progressBar,
        {
          width: ['100%', '0%'],
        },
        {
          easing: 'linear',
          duration: this.timerLength / 1000,
        },
      )
    },

    resumeAnimation() {
      const currentTime = this.timerAnimation.currentTime * 1000
      this.timerAnimation.play()
      this.startTimer(this.timerLength - currentTime)
    },

    pauseAnimation() {
      this.timerAnimation.pause()
      clearTimeout(this.timer)
    },

    animateIn() {
      animate(
        this.$el,
        {
          translateY: 0,
        },
        { easing: spring() },
      )
    },

    async closeNotification() {
      clearTimeout(this.timer)
      const animation = animate(this.$el, {
        opacity: 0,
      })
      await animation.finished
      this.hide = true
    },
  },
}
</script>

<template>
  <div
    class="fixed top-0 right-0 left-0 mt-8 flex flex-col items-center justify-center pointer-events-none font-sans2 z-[10000]"
  >
    <div
      v-show="!hide"
      class="relative mx-auto mb-8 rounded px-8 py-4 text-sm overflow-hidden w-full max-w-md shadow-2xl pointer-events-auto"
      :class="containerClasses"
      @mouseenter="pauseAnimation"
      @mouseleave="resumeAnimation"
    >
      <div ref="progressBar" class="absolute top-0 right-0 h-1" :class="progressBarClasses" />
      <div class="flex">
        <div class="mr-4">
          <svg
            v-if="type === 'info'"
            class="w-10 h-10"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <svg
            v-if="type === 'success'"
            class="w-10 h-10"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <svg
            v-if="type === 'warning'"
            class="w-10 h-10"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <svg
            v-if="type === 'error'"
            class="w-10 h-10"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </div>
        <div class="flex-grow">
          <div class="font-bold" v-html="notification.title" />
          <div class="font-light" v-html="notification.body" />
        </div>
        <div class="cursor-pointer" @click="closeNotification">
          <svg
            class="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>
