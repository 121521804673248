<script>
export default {
  name: 'GolfLogin',
  data() {
    return {
      showLogin: false,
      login: {
        email: null,
        password: null,
      },
      errors: [],
    }
  },
  methods: {
    attemptLogin() {
      // $voixNuxtApi
      //   .post('/login', this.login)
      //   .then(() => {
      //     document.location.href = '/member-home';
      //   })
      //   .catch((response) => {
      //     const { errors } = response.response.data;
      //     for (let j = 0; j < Object.keys(errors).length; j += 1) {
      //       const field = Object.keys(errors)[j];
      //       for (let i = 0; i < errors[field].length; i += 1) {
      //         const error = errors[field][i];
      //         this.addError(error);
      //       }
      //     }
      //   });
    },
    addError(error) {
      this.errors.push(error)

      setTimeout(() => {
        this.errors.splice(this.errors.indexOf(error), 1)
      }, 5000)
    },
  },
}
</script>

<template>
  <span class="text-golf-green">
    <span @click="showLogin = true">
      <slot />
    </span>
    <transition name="fade">
      <div v-if="showLogin" class="fixed pin z-50">
        <div class="bg-black opacity-50 absolute pin z-10" @click="showLogin = false" />
        <div
          class="bg-white shadow-lg rounded absolute top-0 z-20 text-center p-8"
          style="min-width: 400px; background-image: url('/imgs/ui/papyrus.png')"
        >
          <div class="p-8 bg-white shadow">
            <h3 class="text-golf-green mb-6 text-2xl uppercase">Member Login</h3>
            <h2 class="text-zinc-600 text-sm font-sans2 non-italic mb-6">Welcome Back</h2>

            <transition name="fade">
              <ul v-if="errors.length > 0" class="bg-red rounded text-white p-4 text-sm mb-8">
                <li v-for="(error, key) in errors" :key="key">
                  {{ error }}
                </li>
              </ul>
            </transition>

            <fieldset class="mb-8">
              <label>Email</label>
              <input v-model="login.email" type="text">
            </fieldset>
            <fieldset class="mb-8">
              <label>Password</label>
              <input v-model="login.password" type="password">
            </fieldset>
            <button
              class="btn btn-primary bg-golf-action text-white w-full"
              @click.prevent="attemptLogin"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </transition>
  </span>
</template>
